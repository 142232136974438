:root{
  --main-DPfont: "Fira Sans", sans-serif, Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
  --main-DPblack: #333333;
  --main-DPblue: #161B38;
  --main-DPred: #D12A2F;
  --main-DPcoolDarkGray: #4F4F4F;
  --main-DPcoolGray: #BFBFBF;
  --main-DPlightGray: #E9E9E9;
  --main-DPwhite: #FAFAFA;
  --main-DPgreen: #38A700;
  --main-DPturquoise: #75DDDD;
  --main-DPmatteBlue: #508991;
  --main-DPwidth: 80%;

  --main-DP2Red: #d82a30;
  --main-DP2Blue: #2e84cc;
  --main-DP2Green: #2fba51;
  --main-DP2Yellow: #f9be38;
  --main-DP2Orange: #e85b1e;
  --main-DP2YPurple: #99498E;

  --main-DP2Red-light: rgba(216, 42, 48, 0.5);
  --main-DP2Blue-light: rgba(46, 132, 204, 0.3);
  --main-DP2Green-light: rgba(47, 186, 81, 0.25);
  --main-DP2Yellow-light: rgba(249, 190, 56, 0.5);
  --main-DP2Orange-light: rgba(232, 91, 30, 0.5);
  --main-DP2YPurple-light: rgba(153, 73, 142, 0.25);

}

button {
  font-family: var(--main-DPfont) !important;
}

.ui.menu {
  font-family: var(--main-DPfont) !important;
}

.ui.input {
  font-family: var(--main-DPfont) !important;
}

input {
  font-family: var(--main-DPfont) !important;
}

.App{
  text-align: center;
  font-family: var(--main-DPfont);
  color: var(--main-DPblack);
  font-size: 16px;
}

.alert {
  color: var(--main-DP2Orange)
}

/*reset stylesheet*/
/*html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}*/
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.text-transition-inner > div {
  left: 0%
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
  background: rgba(0,0,0,.5) !important;
}

.ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
  background: #fff linear-gradient(transparent,rgba(0,0,0,.3)) !important;
}

.deltaprotocarousel {
  background-color: var(--main-DPlightGray);
  margin: 0 10% 0;
}

.carouselshadow {
  margin-top: 36px;
  box-shadow:inset 0 1px 0 rgba(255,255,255,.6), 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.carousel .slide {
  background-color: var(--main-DPlightGray) !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000000 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000000 !important;
}

a {
  cursor: pointer;
  color: #4183c4;
}

.projectstepfoto div div {
  cursor: unset !important;
}

body,html{
  height: unset !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button-blue {
  color: var(--main-DP2Blue) !important;
}

.ui.blue.message {
  background-color: #dff0ff !important;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.paper-tab {
  margin-top: 16px;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.paper-tab-item-active {
  padding: .5em 1em;
  background-color: white;
  box-shadow: 0 3px 0 0 white, 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  text-decoration: unset;
  font-weight: bold;
}

.paper-tab-item {
  padding: .5em 1em;
  text-decoration: unset;
}

.paper-menu {
  background-color: white;
  height: 48px;
  padding: .5em 1em;
  text-align: left;
  box-shadow: 0 3px 0 0 white, 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #e9e9ea;
}

.paper {
  background-color: white;
  padding: 1em;
  text-align: left;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.customer-divider {
  border-right: 2px solid black !important;
}

/*increase the height with an animation*/
.loadinghistory {
  overflow: hidden;
  transition: max-height 1s ease-out;
  height: auto;
  max-height: 600px;
  text-align: center;
}

.loadinghistory.collapsed {
  max-height: 0px;
  height: 0px;
  transition: max-height 1s ease-out;
}

.bomtable {
  border-collapse: collapse;
  text-align: left;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: .28571429rem .28571429rem;
}

.bomtable th {
  font-weight: bold;
  border: 1px solid lightgray;
  background-color: white;
  vertical-align: text-top;
}

.bomtable tr:first-child > th {
  position: sticky !important;
  top: 0px;
  z-index: 2;
}

.bomtable td {
  border: 1px solid lightgray;
  #overflow: hidden;
  #background-color: white;
  vertical-align: center;
}

.bomtable tr:nth-child(even) {
  #background-color: whitesmoke;
}

.bomtable th {
  padding: 4px !important;
}

.bomtable td {
  padding: 4px;
}

.deltatablediv {
  overflow-x: auto;
  overflow-y: auto;
  #max-height: calc(100vh - 220px);
}

.deltatableparent {
  margin-left: -1px;
  margin-right: 3px;
  width: 100%;
  background-color: white;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.deltatable {
  border-collapse: separate;
  border-spacing: 0px;
  text-align: left;
  background-color: white;
  #border: 1px solid lightgray;
  border-radius: .28571429rem .28571429rem;
  width: calc(100% - 6px);
  table-layout: fixed;
  flex-shrink: 2;
}

.deltatable thead tr td {
  font-weight: bold;
  background-color: #f8f8f9 !important;
  z-index: 7;
}

.deltatable tr:first-child > th {
  position: sticky !important;
  top: 0px;
  z-index: 2;
}

.deltatable thead tr:first-child td {
  border-top: 1px solid lightgray;
}

.deltatable td:first-child {
  border-left: 1px solid lightgray;
}

.deltatable td {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  #overflow: hidden;
  #background-color: white;
  vertical-align: center;
}

.deltatable th {
  padding: 4px !important;
}

.deltatable td {
  padding: 4px;
}

.sidebar-main {
  position: fixed;
  left: 0;
  top: 88px;
  bottom: 0;
  background-color: white;
  z-index: 10;
  transition: width 0.25s;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 30px 0 0;
  text-align: left;
}

.message-sidebar-main {
  position: fixed;
  right: 0;
  top: 88px;
  bottom: 0;
  background-color: white;
  z-index: 10;
  transition: width 0.25s;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: left;
}

.message-sidebar-main h2{
  position: absolute;
  right:12px;
  top:8px;
}

.sidebar-blackout{
  height: 100%;
  position: fixed;
  left: 480px;
  top: 85px;
  background-color: black;
  transition: opacity 0.25s;
  transition-delay: 0.25s;
}

.sidebar-main h2{
  position: absolute;
  left:12px;
  top:8px;
}

.sidebar-main form {
  padding: 10px 0 0;
  white-space: nowrap;
}

.project-overview-header{
   width: 100%;
   height: 40px;
   background-color: white;
   margin-bottom: 30px;
   padding: 0;
   text-align: left;
   border: 1px solid rgba(34,36,38,.15);
 }

.project-container, .project-container-listview{
  width: 100%;
  margin-top: 20px;
  border: 1px solid rgba(34,36,38,.15);
}

.project-container-listview:hover{
  box-shadow: 0px 0px 5px #999;
}

.project-header{
  width: 100%;
  height: 40px;
  background-color: white;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
}

.project-header-labels{
  position: relative;
  top: 6px;
  width: 12%;
  margin: 0 0.5% 0 0.5% !important;
  background-color: white !important;
  font-weight: normal !important;
  color: #333333 !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-align: center !important;
  float: left !important;
}

.project-header .icon, .project-overview-header .icon{
  float: left !important;
}

.project-header-labels:hover{
  background-color: whitesmoke !important;
}

.project-steps{
  margin: 0 !important;
  border: 0 !important;
}

.project-steps .step, .step:after{
  border-radius: 0 !important;
  background: var(--main-DPlightGray) !important;
}

.project-steps .step.active:after, .step.active  {
  background: var(--main-DP2Green)  !important;
}

.project-steps .step.active .title, .step.active .description{
  color: white !important;
}

.project-menu{
  margin-top: 15px !important;
}

.project-segment{
  min-height: 50px;
  overflow: hidden;
  padding: 12px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  text-align: left;
}

.project-segment-button-bar{
  height:36px;
  width: 100%;
  margin: 0;
  margin-bottom: 12px;
}

.project-segment-button-bar Button:last-child{
  margin:0 !important;
}

.project-segment-button-bar .button{
  margin-right: 8px !important;
}

.project-segment-header{
  background-color: var(--main-DPlightGray);
  width: 100%;
  height: 44px;
  margin: 0 0 8px;
  justify-content: space-around;
  display: flex;
  padding-top: 12px;
  font-size: 16px;
}

.project-segment-grid-3{
  float: left;
  height: auto;
  min-height: 150px;
  width: 33%;
  text-align: center;
  margin-top: 8px;
}

.loc-table{
  border: none  !important;
  margin: 0 !important;
}

.loc-table th{
  border-top: 1px solid lightgrey !important;
}

.loc-table th:first-child, .loc-table td:first-child{
  width: 60px;
  text-align: center !important;
}

.loc-table th:nth-child(2){
  width: 100px;
}
.loc-table th:nth-child(3){
  width: 450px;
}
.loc-table th:nth-child(4){
  width: 450px;
}

.loc-table tr:last-child td:first-child, .loc-table tr:last-child td:last-child{
  border-bottom: none;
}

.loc-table tr:last-child td{
  border-bottom: 1px solid lightgrey;
}

.loc-table td:first-child, .loc-table th:first-child{
  border:none !important;
  background: white!important;
}

.loc-table td:last-child, .loc-table th:last-child{
  border-top: none !important;
  border-bottom: none !important;
  background: white !important;
}

.loc-table textarea{
  resize: none;
  margin: 0 !important;
  box-sizing: border-box !important;
  min-height: 56px !important;
  border: 0 !important;
  font-size: 14px !important;
  font-family: Fira Sans !important;
  padding: 21px 0 21px 11px !important;
  width: 100% !important;
}

.hiddenbutton{
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0%;
}

.employee-hours-table .button{
  margin: 0 !important;
  margin-left: 2px !important;
  display: inline-block !important;
  border-radius: 0 !important;
}

.employee-hours-table th{
  background-color: #e8e8e8 !important;
}

.employee-hours-table td{
  font-size: 12px;
}

#employee-hours > .react-datepicker-wrapper{
  width: 200px;
}

#employee-hours > .react-datepicker-wrapper input{
  height: 38px;
  font-family: "Fira Sans", sans-serif, Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
  border-radius: 3px;
  border: 1px solid lightgray;
  padding-left: 3px;
  text-align: center;
}

.employee-hours-table .react-datepicker-wrapper input{
  width: 44px;
  height: 32px;
  display: inline-block;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif, Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
  border-radius: 3px;
  border: 1px solid lightgray;
  text-align: center;
  margin: 4px;
}

.menu-text{
  font-size: 14px;
}

.menu-divider{
  font-size: 12px !important;
}

.menu-title{
  font-size: 16px;
}

.tableFixHead {
  #overflow: auto;
  overflow-x: auto;
  #overflow-y: hidden;
  #max-height: 80vh;
}

.microplacerTable {
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 248px);
}

.microplacerTable-notscrolling {
  margin: auto;
}

.ui.deltaproto.menu, .deltaproto.active.item {
  background-color: #e0e1e2;
}

.activehighlight {
  background: rgba(0,0,0,.05) !important;
  color: rgba(0,0,0,.95) !important;
  font-weight: 400 !important;
  box-shadow: none !important;
}

.microplacerMenu {
  line-height: 36px;
  margin-left: 16px;
  height: 36px;
  margin-top: 6px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  #outline: 1px solid lightgray;
  z-index: 99;
}

/* no need to display empty elements */
.designator:empty {
  display: none;
}

.designator:not(:last-child):after {
  content: ", ";
}

.btitem {
  height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.cornertopright {
  vertical-align: top;
  text-align: right;
  padding: 0;
  margin: 0;
  float: right;
}

.bomcustomer {
  background-color: lightgrey;
}

.supplierimageparent {
  display: inline-block;
}

.supplierimage {
  #width: 50px;
  text-align: center;
  display: inline-block;
  mix-blend-mode: multiply;
}

.klantimage {
  width: 50px;
  text-align: center;
  display: inline-block;
  background-color: #0d71bb;
  color: white;
  margin-right: 4px;
  padding-right: 1px;
}

.manualimage {
  width: 60px;
  text-align: center;
  display: inline-block;
  background-color: #e85b1e;
  color: white;
  margin-right: 4px;
  padding-right: 1px;
}

.ui.dropdown.designator>.dropdown.icon {
  margin-left: 2px !important;
}

.text-transition {
  display: inline-block !important;
}

.bst-row.selected td {
  background-color: #2e84cc35;
}

.bst-row.selectedproduction td {
  box-shadow: 0 0 0 3px #2e84cc inset;
}

.bst-row.unselected td {
  transition: background-color 3s ease-in;
}

.bst-row.unselectedproduction td {
  transition: box-shadow 3s ease-in;
}

.bst-prod-row.buddy td {
  background-color: var(--main-DP2YPurple-light);
}

.bst-prod-row.buddy.selected td {
  #filter: brightness(85%);
}

.bst-prod-row.done td {
  background-color: var(--main-DP2Green-light);
}

.bst-prod-row.selected {
  box-shadow: 0 0 0 3px #2e84cc inset;
}

.bst-prod-row.dnp td {
  background-color: var(--main-DP2Red-light);
}

/*.bst-prod-row.done.selected td {
  filter: brightness(85%);
}*/

.bst-prod-row.busy td {
  background-color: var(--main-DP2Yellow-light);
}

.bst-prod-row.busy.selected td {
  filter: brightness(85%);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkin input{
  text-align: center !important;
  border-radius: 25px !important;
}

.checkin-search input{
  border-radius: 25px !important;
  height: 34px;
  margin-top: 2px;
}

.err {
  display: inline-block;
  position:relative;
}

.err_blue {
  display: inline-block;
  position:relative;
}

.bst-image {
  mix-blend-mode: multiply;
}

.Highlight {
  background-color: #ffd54f;
}

.dp-part-row {
  background-color: rgba(27, 183, 22, 0.2) !important;
}

.dp-error {
  background-color: rgba(209,42,47, 0.2) !important;
}

.designatorcolumn {
  max-height: 57px;
  overflow: hidden;
  line-height: 19px;
}

.designatoredit {
  display: unset;
  float: right;
  margin-top: 38px;
}

.customerdataedit {
  text-align: right;
  flex: 1
}

.order-extra {
  background-color: gray;
  border-radius: 8px;
  min-width: 16px;
  text-align: center;
  color: white;
  font-weight: bold;
  max-height: 16px;
  line-height: 16px;
  font-size: 13px;
  padding-left: 4px;
  padding-right: 4px;
}

.tabtoolbox {
  display: flex;
  gap: 8px;
  #background: var(--main-DPcoolGray);
  #border-color: transparent transparent var(--main-DPcoolGray) var(--main-DPcoolGray);
  padding: 12px;
  padding-left: 12px;
}

.tabtoolbox > div {
  line-height: 34px;
}

.ui.menu .active.item {
  #background: var(--main-DPcoolGray) !important;
}

.ui.pointing.menu .active.item:after {
  #background-color: var(--main-DPcoolGray) !important;
}

.ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
  padding-left: 3.07142857em!important;
}

.fittedsvg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui.input.dirty > input {
  font-weight: bold;
}

.bommenu {
  margin-bottom: 14px;
  min-height: 36px;
}

.svgoverlayshadow {
  filter: drop-shadow(0.5px 0.5px 0.2px white) drop-shadow(-0.5px -0.5px 0.2px white);
}

.svgrectfill {
  fill: rgb(63,99,56);
  stroke: white;
  stroke-width: 100;
  fill-opacity: .3;
  stroke-opacity: 0.8
}

.svgbackground {
  #background: radial-gradient(#eee,#777);
}

.svghover:hover {
  fill: white;
}

#expand-container {
  overflow: hidden;
}

#expand-contract {
  margin-top: -100%;
  transition: all 1s;
}

#expand-contract.expanded {
  margin-top: 0;
}

.sharketingTable {
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 174px);
}

.sharketingitem {
  max-width: calc(100vw - 32px - 64px) !important;
  margin-left: 32px !important;
}

.sharketing {
  padding-bottom: 0;
}

.microplacermain {
  background-color: white;
  text-align: left;
}

.microplacermainhero {
  padding: 1em;
  max-width: 1440px;
  margin: auto;
}

.microplacerherotext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.microplacersmallhero {
  padding: 1em;
  max-width: 1080px;
  margin: auto;
}

.microplacerheader {
  font-size: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.microplacerimg {
  width: 100%
}

.microplacertextbox {
  #border: 1px solid lightgray;
  padding: 1em;
}

.tablefiltericon {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0px;
  margin-top: 24px;
  height: calc(100% - 24px);
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ui.sortable.table thead th.filtered:after {
  content: '\f0b0';
}

.ui.dropdown .menu {
  z-index: 999 !important;
}

.ui.very.compact.table td {
  padding: 0 .6em !important;
}

.ui.compact.table th {
  padding: 0px;
  padding-bottom: 6px;
}

.compactdropdown {
  padding: 0 !important;
  margin-bottom: -2.5px !important;
}

.videoInsert {
  position: absolute;
  right: 0;
  top: 0;
  #height: 100%;
  #background-size: cover;
  #overflow: hidden;
}

.webcam-crosshair {
  --widthA: 100px;
  width: var(--widthA);
}

.ui.toggle.checkbox.white label {
  color: white;
}

.ui.checked.toggle.checkbox.white label {
  color: white !important;
}

/*this is not working*/
.ui.checked.toggle.checkbox.white label:focus {
  color: white !important;
}

.fixcolorloader::before {
  border-color: lightgrey !important;
}

.tabbuttons button {
  background: transparent;
  color: rgba(0,0,0,.95);

  font-weight: 700;
  margin-bottom: -1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0 0 .28571429rem .28571429rem  !important;

  #border: none;

  border-bottom-width: 1px;
  border-color: #d4d4d5;

  cursor: pointer;
  text-decoration: none;
  display: inline;
  padding: 0;
}

.pulsing {
  color: white;
  animation-name: pulsingcolor;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes pulsingcolor {
  0% {
    color: white;
  }
  50% {
    color: var(--main-DPgreen)
  }
  100% {
    color: white;
  }
}

.noopacity {
    opacity: 1 !important;
}

.sortableHelper {
  z-index: 9999 !important;
}

.partinfodatasheet {
  width: 30vw;
  max-width: 100%;
}

.pdf_canvas {
  margin: 15px;
  border: solid #c6c6c6 1px;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 5px 0px;
}

.blogitem img {
  max-width: 100%;
}

.menu {
  display: flex;
  flex-wrap: wrap;
}

.menu {
  display: flex;
  flex-wrap: wrap;
}